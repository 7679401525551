<template>
  <div>
    <button-top v-if="permissions.c" :must-icon="false" tooltip="Crear especie" name="Crear" @action="store"></button-top>
    <validation-observer ref="form">
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Nombre comun / producto</label>
              <validation-provider #default="{ errors }" name="Nombre producto" rules="required">
                <b-form-input id="name" v-model="form.name" :state="errors.length > 0 ? false : null" name="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="scientific_name" class="text-success">Nombre cientifico</label>
              <validation-provider #default="{ errors }" name="Nombre cientifico" rules="required">
                <b-form-input id="scientific_name" v-model="form.scientific_name"
                  :state="errors.length > 0 ? false : null" name="scientific_name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="family_id" class="text-success">Familia</label>
              <validation-provider #default="{ errors }" name="Familia" rules="required">
                <b-select-created name="family_id" id="family_id" v-model="form.family_id" :options="list.families"
                  on-value="id" on-text="name" @create="form_family.name = ''; create_family = true"
                  :state="errors.length > 0 ? false : null"> </b-select-created>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="range_id" class="text-success">Rango altitud (m.s.n.m)</label>
              <validation-provider #default="{ errors }" name="Rango altitud (m.s.n.m)" rules="required">
                <b-form-select id="range_id" class="fl" v-model="form.range_id" value-field="id" text-field="name"
                  :options="list.ranges" :state="errors.length > 0 ? false : null" name="range_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="origin_id" class="text-success">Origen</label>
              <validation-provider #default="{ errors }" name="Origen" rules="required">
                <b-form-select id="origin_id" class="fl" v-model="form.origin_id" value-field="id" text-field="name"
                  :options="list.origins" :state="errors.length > 0 ? false : null" name="origin_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="habit_id" class="text-success">Habito</label>
              <validation-provider #default="{ errors }" name="Habito" rules="required">
                <b-form-select id="habit_id" class="fl" v-model="form.habit_id" value-field="id" text-field="name"
                  :options="list.habits" :state="errors.length > 0 ? false : null" name="habit_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="type_id" class="text-success">Tipo de semilla</label>
              <validation-provider #default="{ errors }" name="Tipo de semilla" rules="required">
                <b-form-select id="type_id" class="fl" v-model="form.type_id" value-field="id" text-field="name"
                  :options="list.types" :state="errors.length > 0 ? false : null" name="type_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="climate_id" class="text-success">Tipo de clima</label>
              <validation-provider #default="{ errors }" name="Tipo de clima" rules="required">
                <b-form-select id="climate_id" class="fl" v-model="form.climate_id" value-field="id" text-field="name"
                  :options="list.climates" :state="errors.length > 0 ? false : null" name="climate_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="main_use_id" class="text-success">Uso principal</label>
              <validation-provider #default="{ errors }" name="Uso principal" rules="required">
                <b-form-select id="main_use_id" class="fl" v-model="form.main_use_id" value-field="id" text-field="name"
                  :options="list.main_uses" :state="errors.length > 0 ? false : null" name="main_use_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="6">
            <b-form-group class="w-100">
              <label for="secondary_use_id" class="text-success">Uso secundario</label>
              <validation-provider #default="{ errors }" name="Uso secundario" rules="required">
                <b-form-select id="secondary_use_id" class="fl" v-model="form.secondary_use_id" value-field="id"
                  text-field="name" :options="list.secondary_uses" :state="errors.length > 0 ? false : null"
                  name="secondary_use_id"></b-form-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Descripcion</label>
              <validation-provider #default="{ errors }" name="Descripcion" rules="required">
                <b-form-textarea id="description" v-model="form.description" :state="errors.length > 0 ? false : null"
                  name="description" rows="3" max-rows="6"></b-form-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <b-modal v-model="create_family" title="Agregar nueva familia" size="md" hide-footer no-close-on-backdrop>
      <b-row>
        <b-col>
          <validation-observer ref="form_family">
            <b-form-group class="w-100">
              <label for="name" class="text-success">Nombre</label>
              <validation-provider #default="{ errors }" name="Nombre" rules="required">
                <b-form-input id="name" v-model="form_family.name" :state="errors.length > 0 ? false : null"
                  name="name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row align-h="end">
        <b-col cols="4">
          <b-button variant="outline-secondary" block @click="create_family = false">Cancelar</b-button>
        </b-col>
        <b-col cols="4">
          <b-button variant="primary" block @click="storeFamily()">Crear</b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
  
<script>

export default {
  data() {
    return {
      form: {
        name: '',
        scientific_name: '',
        family_id: '',
        range_id: '',
        origin_id: '',
        habit_id: '',
        type_id: '',
        climate_id: '',
        main_use_id: '',
        secondary_use_id: '',
        description: '',
      },

      list: {
        families: [],
        ranges: [],
        origins: [],
        habits: [],
        types: [],
        climates: [],
        main_uses: [],
        secondary_uses: [],
      },
      create_family: false,

      form_family: {
        name: '',
      }
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    async init() {
      await this.getPermission('c');
      await this.get();
    },

    async get() {
      try {
        this.loading();
        this.list.climates = (await this.$http.get('production/specie-climate/index')).data.data;
        this.list.families = (await this.$http.get('production/specie-family/index')).data.data;
        this.list.habits = (await this.$http.get('production/specie-habit/index')).data.data;
        this.list.main_uses = (await this.$http.get('production/specie-main-use/index')).data.data;
        this.list.origins = (await this.$http.get('production/specie-origin/index')).data.data;
        this.list.ranges = (await this.$http.get('production/specie-range/index')).data.data;
        this.list.secondary_uses = (await this.$http.get('production/specie-secondary-use/index')).data.data;
        this.list.types = (await this.$http.get('production/specie-type/index')).data.data;
      } catch (err) {
        this.notify("Error", err.response.data.message, 'danger')
      } finally {
        this.not_loading();
      }
    },

    async storeFamily() {
      this.$refs.form_family.validate().then(success => {
        if (success) {
          this.loading();
          this.$http
            .post('/production/specie-family/store', this.form_family)
            .then(async res => {
              this.notify("Proceso satisfactorio.", res.data.message, 'primary')
              this.list.families = (await this.$http.get('production/specie-family/index')).data.data;
              this.form.family_id = res.data.id;
              this.create_family = false;
            })
            .catch(err => {
              this.notify("Error", err.response.data.message, 'danger')
            }).finally(
              () => {
                this.not_loading();
              }
            )
        }
      }
      )
    },

    store() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading();
          this.$http
            .post('/production/specie/store', this.form)
            .then(res => {
              this.notify("Proceso satisfactorio.", res.data.message, 'primary')
              this.$router.push({ name: 'gestion-especies' })
            })
            .catch(err => {
              this.notify("Error", err.response.data.message, 'danger')
            }).finally(
              () => {
                this.not_loading();
              }
            )
        }
      }
      )
    },


  },


}
</script>